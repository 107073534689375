import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectTemplateDialogComponent } from './project-template-dialog.component';

@NgModule({
  declarations: [ProjectTemplateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,

    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    ScrollingModule,
    TranslateModule,
  ],
  exports: [ProjectTemplateDialogComponent],
})
export class ProjectTemplateDialogModule {}
